<template>
  <div class="home">
    <el-row>
      <el-col :span="12">
        <img src="@/assets/logo.jpg" alt="logo" style="width: 100%;" />
      </el-col>
      <el-col :span="12">
        <el-form ref="form" :model="form" :rules="rules" label-width="150px" style="padding:30px ;">
          <el-form-item label="Email:" prop="email">
            <el-input v-model="form.email" placeholder="name@example.com"></el-input>
          </el-form-item>

          <el-form-item label="Name:" prop="name">
            <el-input v-model="form.name" placeholder="xbloom"></el-input>
          </el-form-item>
          <el-form-item label="Region:" prop="region">
            <el-select filterable clearable v-model="form.region" placeholder style="float: left;">
              <el-option
                v-for="item in countryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <el-row>
                  <el-col :span="12">
                    <span style="float: left;">{{item.name}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span style="float: right;">{{item.code}}</span>
                  </el-col>
                </el-row>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Account Manager:" prop="recommend">
            <el-select
              filterable
              clearable
              v-model="form.recommend"
              placeholder="name@example.com"
              style="float: left;"
            >
              <el-option
                v-for="item in recommendList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >{{item.name}}</el-option>
            </el-select>
          </el-form-item>
          <div>
            <el-form-item label="Product Name:" prop="capsuleName">
              <el-input v-model="form.capsuleName" placeholder="Southern weather" clearable />
            </el-form-item>

            <el-form-item label="Product Image:" prop="imagePath">
              <uploadImage :limit="1" v-model="form.imagePath"></uploadImage>
            </el-form-item>

            <el-form-item label="Roaster Name:" prop="roasterName">
              <el-input v-model="form.roasterName" placeholder="Onyx Coffee Lab" clearable />
            </el-form-item>
            <el-form-item label="Roasting Level:" prop="roastingLevel">
              <el-select
                clearable
                class="ele-block"
                v-model="form.roastingLevel"
                placeholder
                style="float: left;"
              >
                <el-option
                  v-for="item in roastList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Process Method:" prop="process">
              <el-input v-model="form.process" placeholder="Anaerobic · Natural" clearable />
            </el-form-item>
            <el-form-item label="Type:" prop="type">
              <el-select
                clearable
                class="ele-block"
                v-model="form.type"
                placeholder
                style="float: left;"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Origin:" prop="origin">
              <el-input v-model="form.origin" placeholder="Colombia · Ethiopia" clearable />
            </el-form-item>
            <el-form-item label="Tasting Notes:" prop="tastingNotes">
              <el-input
                v-model="form.tastingNotes"
                placeholder="Milk Chocolate ·Plum ·Juicy"
                clearable
              />
            </el-form-item>
            <el-form-item label="Elevation:" prop="elevation">
              <el-input v-model="form.elevation" placeholder="1600- 1900 masl." clearable />
            </el-form-item>
            <el-form-item label="Share Link:" prop="link">
              <el-input
                v-model="form.link"
                placeholder="https://share -h5.xbloom.com/?id=example"
                clearable
              />
            </el-form-item>
            <div class="el-form-item" style="font-size:12px;text-align:left;background-color:grap;">
              <label for="link" class="el-form-item__label" style="width: 150px;">&nbsp;</label>
              <div class style="margin-left: 150px;">
                Here are some guidelines for creating recipes, which we’d appreciate if you could also share with your roaster partners:
                <br />1. For recipes created on the Original model: All recipe links are compatible for writing.
                <br />2. For recipes created on the Studio model: Please follow these specific parameters to ensure successful writing:
                <br />Grind size must be between 41 and 70.
                <br />Water/coffee ratio must be an integer, so the total water amount should be a multiple of 15.
                <br />
              </div>
            </div>
            <el-form-item label="Captcha" prop="captcha">
              <div class="login-input-group">
                <el-row>
                  <el-col :span="12">
                    <el-input
                      clearable
                      v-model="form.captcha"
                      prefix-icon="el-icon-_vercode"
                      placeholder="please input code"
                    />
                  </el-col>
                  <el-col :span="12">
                    <img
                      alt
                      v-if="captcha"
                      :src="captcha"
                      @click="changeCode"
                      class="login-captcha"
                    />
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
            <div style="padding:20px 8%;text-align: center;">
              <el-button type="primary" :loading="loading" @click="save">Submit</el-button>
            </div>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import uploadImage from '@/components/uploadImage'
export default {
  components: { uploadImage },
  data() {
    // var validateEmail = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('Please enter Email'))
    //   } else {
    //     var date = { email: this.form.email }
    //     this.loading = true
    //     this.$http
    //       .post('/distributioninfo/getAccountStatus', date)
    //       .then((res) => {
    //         this.loading = false
    //         //Account already exists
    //         if (res.data.code === 0) {
    //           callback()
    //         }
    //         //already
    //         if (res.data.code === -1) {
    //           this.$message.error(res.data.msg)
    //         }
    //       })
    //       .catch((e) => {
    //         this.loading = false
    //         callback(new Error(e.msg))
    //       })
    //   }
    // }
    return {
      // 表单数据
      form: {
        // 表单数据
        id: '',
        pid: '',
        theName: '',
        imagePath: '',
        introduce: '',
        flavor: '',
        subtitle: '',
        roasterName: '',
        roast: '',
        origin: '',
        process: '',
        type: '',
        varietal: '',
      },
      // 表单验证规则
      rules: {
        email: [
          { required: true, message: 'Please enter Email', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'Please enter Name', trigger: 'blur' },
        ],
        region: [
          {
            required: true,
            message: 'Please choice Region',
            trigger: 'change',
          },
        ],
        recommend: [
          {
            required: true,
            message: 'Please enter Account Manage',
            trigger: 'change',
          },
        ],
        capsuleName: [
          {
            required: true,
            message: 'Please choice capsuleName',
            trigger: 'blur',
          },
        ],

        roasterName: [
          {
            required: true,
            message: 'Please enter roasterName',
            trigger: 'blur',
          },
        ],

        link: [
          {
            required: true,
            message: 'Please enter link',
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: 'Please input code',
            trigger: 'blur',
          },
        ],
      },
      // 提交状态
      loading: false,
      roastList: [
        { id: 1, name: 'Light' },
        { id: 2, name: 'Light - medium' },
        { id: 3, name: 'Medium' },
        { id: 4, name: 'Medium - dark' },
        { id: 5, name: 'Dark' },
      ],
      recommendList: [
        { id: 1, name: 'x@tbdx.desgin' },
        { id: 2, name: 'jen@tbdx.desgin' },
        { id: 3, name: 'tiapengsuju@tbdx.club' },
      ],
      typeList: [
        { id: 1, name: 'Single Origin' },
        { id: 2, name: 'Blend' },
      ],

      //国家列表
      countryList: [],
      // 验证码base64数据
      captcha: '',
    }
  },
  mounted() {
    this.getCountry()
    this.changeCode()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http
            .post('/distributionpods/edit', this.form)
            .then((res) => {
              this.loading = false
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                setTimeout(() => {
                  window.location.reload()
                }, 1000) // 等待5000毫秒后刷新页面
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        } else {
          return false
        }
      })
    },
    /* 查询国家列表 */
    getCountry() {
      this.$http
        .get('/countrycode/getCountry')
        .then((res) => {
          if (res.data.code === 0) {
            this.countryList = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更换图形验证码 */
    changeCode() {
      // 这里演示的验证码是后端返回base64格式的形式
      this.$http
        .get('/captcha')
        .then((res) => {
          if (res.data.code === 0) {
            this.captcha = res.data.data.captcha
            this.form.key = res.data.data.key
            this.$refs.form.clearValidate()
          } else {
            this.$message.error(res.data.msg)
              // 重新刷新验证码
              this.changeCode()
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
  },
}
</script>


<style>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>